import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "AVIRA Antivirus Pro",
  "path": "/Quick_Installation/Live_Video/Troubleshooting/AVIRA/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Set an Exception for your IP Camera",
  "image": "./QI_SearchThumb_LiveVideo_Avira.png",
  "social": "/images/Search/QI_SearchThumb_LiveVideo_Avira.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='AVIRA' dateChanged='2017-11-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Flash Video Plugin to view your Camera`s Live Video' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/Problembehebung/AVIRA/' locationFR='/fr/Quick_Installation/Live_Video/Troubleshooting/AVIRA/' crumbLabel="AVIRA" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "set-an-exception-rule-inside-your-avira-antivirus-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#set-an-exception-rule-inside-your-avira-antivirus-software",
        "aria-label": "set an exception rule inside your avira antivirus software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set an Exception rule inside your AVIRA Antivirus Software`}</h2>
    <p>{`Avira Antivirus Pro might cause prevent you from displaying the video stream when you access our IP cameras for the first time with Internet Explorer (other browsers are not affected). The problem is caused by Avira´s real-time scanner blocking the ActiveX control used by the camera (SEWebClientPlus.ocx for the HD cameras or DVM_IPCam2.ocx for VGA cameras). Please deactivate the real-time scanner and reload the camera WebUI inside your Internet Explorer. You will be asked to install the ActiveX again - simply confirm the installation process, then reactivate your Antivirus protection. Reload the WebUI - the video should now load automatically.`}</p>
    <h3 {...{
      "id": "open-the-configuration-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#open-the-configuration-menu",
        "aria-label": "open the configuration menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Open the Configuration Menu`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/16b121afe47483487a73723f0d4faad2/772e8/Anvira_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "112.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAIAAACEf/j0AAAACXBIWXMAAC4jAAAuIwF4pT92AAADVElEQVQ4y3WT30/bVhTH8x/tdX/DnjZpq1RN2pQX1AeoQDysSAykaVK3aS+o6igkcfyjDcKiCUkaFMdJRpUGkjguHhoxFAL7ka1IEEJynTi27/WZck26hsJHV9bROd/vPcfXvj6O43iefzrkGYUXhEgkskKJRCI8zwuCsLi4qGkaALiuCxSfLMsAgDEGmsYUT3EVu66n1jStUqmMmLO5HAC0O6i6s3t4/IeX1ev/njTOvLi0/TrxQkJGr1bbK5fLN3Q+qJ98+bX/0ZMAADi2M/4d9/0v6wMdhrsTn3z0qW/nN/no6M9SaXu0czYLAI3jk+XHT9LJDehbALDApcPPXw50BOZ/nvVPffzPX+r+Qb1cLo2Yc/E4jI25U1Pw7dxg+f2wsEAAsO14CnABOwQAdnd3r79zjmVhctK5aJly1qrVSFXFk5P9wcDEU3R7RrvTutVM5ubQq63Tz744/crfLxZb9+/vHLypHx56x14ul4vFIkJob++DA8uxrDs/f6nrv39+5+jBjK1pzYmJ+tu37VaLkKtpC4VCu92+ycxxMDbmNBp/v6ye6SeQy+Lx8YGJXI1tWdbl5eUtYycSMD3tTk87D2admVm4dw+CAfqRMAx5N8J1cyaTIQCm61oAFkAfwCSkTzHpGgSmCQCqqiqKMmKWJMnumwZCXWSgDuoi1EOoS+n1umZvEBiGgTFWKB+YbRsZRt/sNs46ytHFq/2menxROmxuv2meNju9XhehW8zeH4YxJoRgTEwbmxa2HNK3cc/CtkMIIY7j3HwxRFFUVbVSqXgbVxWlWqVPRVFpoChKpVLRNG19fb1arY6Y6/W6ruv7N6Hrem2/9n9cq52fn1sUh+JLp9PSkAxFkqR8PpfJZPLZX/PSpiRJ6XQ6n8+XSqXNzc1CoSDLcjweTyQSPmZIMBgMBAJLS0uCIMjZXOx5/OHCD3PMN+EQG2JCHMetUqLRqCiKDMOEw2EfPyQajSaTyXg8LvD8i1Qquhb76dGP84EZgRc8werqqiiKKysrLMt6GV+YwjDMxsbG1taWLMvLy8tMaIDAC0/Dz7wqy7KxWCyZTKZSKS8z6Pxu7NAQr0AVoRAT8qrCkAjF8/ve91zb5X3W1taSlFQqFYvFWJYNBoP/AR+RYdjFiODbAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/16b121afe47483487a73723f0d4faad2/f13a9/Anvira_01.avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/16b121afe47483487a73723f0d4faad2/ba381/Anvira_01.webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/16b121afe47483487a73723f0d4faad2/772e8/Anvira_01.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/16b121afe47483487a73723f0d4faad2/772e8/Anvira_01.png",
              "alt": "AVIRA Internet Security",
              "title": "AVIRA Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please right-click the Avira Umbrella Icon in the taskbar systray next to the system clock.`}</p>
    <h3 {...{
      "id": "deactivate-the-real-time-scanner",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#deactivate-the-real-time-scanner",
        "aria-label": "deactivate the real time scanner permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deactivate the Real-Time Scanner`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fd853b3cd77fe4f0667fef09d175b9e0/772e8/Anvira_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "112.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAAsTAAALEwEAmpwYAAADsklEQVQ4y41U2W4bNxTV7xXoX/QD+gUFmqBBUbd1Xpo8dEkXNG7RJehLijiuYMFLtFhTS7ORQ84mKVpnRhppZKOxLJ+CV5ZqW3YRAgcEOcPDc++5vDl0u9Deex+FjS9hPNhE+YOPod37FMcfbeLv+5+h9uAhzdq9Ddo3Nx4hfPwDgkffI3h8iS++g//VFl5/+AlyiCLs3d/Ey192UN96hsOvf8Srb7agPX2G4pMtlL79mVB88hPt678+R/jyAMGLPQTb+wu82IOfL6H19Hfkzs7n+GOnhMJuBa3SKzi7BXj7B3Dyeci9ffD8XxCFAux8Hil38Cb0MXE4MuFcw1Q4GAsHudn5ObaqLp7zDqTnwRQS3PPApAuDcViOhGEzWI6AyQUGoxTpyQmG0ylG13CCZDy+JCwy/Gk0EHguDN0AZwxSCNiWBW4z2KYFZlowDROt1muko9E1jC7nJEkU4Ry/lRl2rAbCIIBe08E5hytdOIzDthlMXZEZMOo6dN1AHCdI05SIllBrIjw7O0OzO8BgNEYjDCGlhB8ECMMQQRCg2WwiCEM0W63VweFweCviOF4QRoM+ptkEtdoxdgsFFItFlMoVaNUjVCpl7B8eolqtYjLJFsrSlOYl1hT2+wOMx2NSxCwbnuvBkxLS9eFwTqEaNR3Voyqk9Ojfq+GuEQ4GijAlM/S6AWZZZA6zOV0ghUS73Uav10MUxWvK7iR0hUDtWCdiUqWbqFaO4PsBsixbU3bVmFsJBeeo1Qw4jMHhDoQjwGyGSqmCcrEMyzShHWkoF0s4KldxeHAIISQmk8l/ZbMiTFNIR+BYO4bNOLhto17Xqe6GyRBJHNMB5aaaFfr9Pq2XKbihcFE2KmeOEGSGaVrodrpIx4tSuc0INS+/UdnM5/OVQk9IWBajfJ2enhJUOOqyJW4W9FoO1aLX61MOVci2xdDudNBRaHfI2V63R+F1u11ScdXZNZe3t7cRRRHSdERvWdM0Umma5qKElOO6QVDm+L6PSZbdrdC27ZVLqgnodR3MtqkRuK4LUzcQBiGy6fTtQlZK6MckRuB51AxUYxDcofA5dyjUZQ2mN57dCuMxmZNTXSTNMgxnM8gggKmbRMgtm0K2lNO93qpU7sIwSRBFA+RsKTFrd5B8/hBvGg1qnnEUQY3FU4sodAU1VJnNZrM7kWO+h3+Uu++8i5NaDe04RrPRoMOqL9brdWppnufR3sXFBf5v5JSLagySKWbzC1zM56uP6kY1FOFSoapbRXoX/gVcj4fZFVBXAwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd853b3cd77fe4f0667fef09d175b9e0/f13a9/Anvira_02.avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fd853b3cd77fe4f0667fef09d175b9e0/ba381/Anvira_02.webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd853b3cd77fe4f0667fef09d175b9e0/772e8/Anvira_02.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fd853b3cd77fe4f0667fef09d175b9e0/772e8/Anvira_02.png",
              "alt": "AVIRA Internet Security",
              "title": "AVIRA Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose to deactivate the real-time scanner and install the ActiveX plug in. Reactivate the scanner afterwards.`}</p>
    <p>{`The ActiveX plugin for the HD camera can be found in C:/Windows/SystemWOW64 (or System32) and is called SEWebClientPlus.ocx for the HD cameras (or DVM_IPCam2.ocx in C:\\Windows\\Downloaded Program Files for the VGA cameras). In case that the HD camera´s video is working but you cannot start the integrated media player - you will need an exception for the file SEPlayerPlus.exe that is also located in C:/Windows/SystemWOW64 (or System32).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      